<template>
  <div>
    <validation-error-alert
      v-if="validationErrors"
      :errors="validationErrors"
    ></validation-error-alert>

    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="*mail 標題"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        :state="v$.sqlRunner.mail_subject.$error ? false : null"
        v-model="sqlRunner.mail_subject"
        :readonly="isReadonly"
      ></b-form-input>
      <b-form-invalid-feedback :state="!v$.sqlRunner.mail_subject.$error">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="mail 內容"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        v-model="sqlRunner.mail_content"
        :readonly="isReadonly"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="*mail 收件人"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        :state="v$.sqlRunner.mail_to.$error ? false : null"
        v-model="sqlRunner.mail_to"
        :readonly="isReadonly"
      ></b-form-input>
      <b-form-invalid-feedback :state="!v$.sqlRunner.mail_to.$error">
        此欄位為必填，且須為 email 格式
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="mail 副本"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        v-model="sqlRunner.mail_cc"
        :readonly="isReadonly"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="mail 密件副本"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        v-model="sqlRunner.mail_bcc"
        :readonly="isReadonly"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="描述"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        v-model="sqlRunner.description"
        :readonly="isReadonly"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="*cronjob 設定"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        :state="v$.sqlRunner.cron_schedule.$error ? false : null"
        v-model="sqlRunner.cron_schedule"
        :readonly="isReadonly"
      ></b-form-input>
      <b-form-invalid-feedback :state="!v$.sqlRunner.cron_schedule.$error">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="*SQL"
    >
      <editor
        v-model="sqlRunner.sql"
        :disabled="isReadonly"
        width="100%"
        height="200px"
        @init="editorInit"
        lang="sql"
        theme="chrome"
      >
      </editor>
      <b-form-invalid-feedback :state="!v$.sqlRunner.sql.$error">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="*樣式"
    >
      <b-form-select
        class="mb-2 mr-sm-2 mb-sm-0"
        v-model="sqlRunner.format"
        :state="v$.sqlRunner.format.$error ? false : null"
        :options="formatOptions"
        :disabled="isReadonly"
      ></b-form-select>
      <b-form-invalid-feedback :state="!v$.sqlRunner.format.$error">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="*起始時間"
    >
      <div class="row">
        <div class="col-12 col-xl-6">
          <datepicker
            placeholder="Select Date"
            v-model="startAt.date"
            bootstrap-styling
            format="yyyy-MM-dd"
            :language="zh"
            :disabled="isReadonly"
          ></datepicker>
          <b-form-invalid-feedback
            :state="!v$.startAt.date.required.$error"
          >
            此欄位為必填
          </b-form-invalid-feedback>
        </div>

        <div class="col-12 col-xl-6">
          <vue-timepicker
            format="HH:mm"
            v-model="startAt.time"
            :input-class="['form-control']"
            :disabled="isReadonly"
          ></vue-timepicker>
          <b-form-invalid-feedback
            :state="
              !v$.startAt.time.HH.$error ||
              !v$.startAt.time.mm.$error
            "
          >
            此欄位為必填
          </b-form-invalid-feedback>
        </div>
      </div>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="*結束時間"
    >
      <div class="row">
        <div class="col-12 col-xl-6">
          <datepicker
            placeholder="Select Date"
            v-model="endAt.date"
            bootstrap-styling
            format="yyyy-MM-dd"
            :language="zh"
            :input-class="{
              'is-invalid': v$.startAt.$invalid,
            }"
            :disabled="isReadonly"
          ></datepicker>
          <b-form-invalid-feedback :state="!v$.endAt.date.$error">
            此欄位為必填
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            :state="!v$.startAt.date.beforeEndAt.$invalid"
          >
            結束時間必須大於起始時間
          </b-form-invalid-feedback>
        </div>

        <div class="col-12 col-xl-6">
          <vue-timepicker
            :input-class="[
              'form-control',
              {
                'is-invalid':
                  v$.startAt.time.HH.$error ||
                  v$.startAt.time.mm.$error ||
                  v$.startAt.date.beforeEndAt.$invalid,
              },
            ]"
            v-model="endAt.time"
            :disabled="isReadonly"
          ></vue-timepicker>
          <b-form-invalid-feedback
            :state="
              !v$.endAt.time.HH.$error || !v$.endAt.time.mm.$error
            "
          >
            此欄位為必填
          </b-form-invalid-feedback>
        </div>
      </div>
    </b-form-group>

    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="是否啟用"
    >
      <b-form-checkbox
        class="mb-2 mr-sm-2 mb-sm-0"
        v-model="sqlRunner.is_enabled"
        :disabled="isReadonly"
      ></b-form-checkbox>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="無資料時是否寄送"
    >
      <b-form-checkbox
        class="mb-2 mr-sm-2 mb-sm-0"
        v-model="sqlRunner.send_when_empty"
        :disabled="isReadonly"
      ></b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import editor from 'vue2-ace-editor'
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { isBefore, set } from "date-fns";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import { zh } from "vuejs-datepicker/dist/locale";

export default {
  components: { editor, Datepicker, VueTimepicker },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      sqlRunner: {
        cron_schedule: { required },
        sql: { required },
        format: { required },
        mail_subject: { required },
        mail_to: { required, email },
      },
      startAt: {
        date: {
          required,
          beforeEndAt: () => {
            const startDate = set(new Date(this.startAt.date), {
              hours: this.startAt.time.HH,
              minutes: this.startAt.time.mm,
            });

            const endDate = set(new Date(this.endAt.date), {
              hours: this.endAt.time.HH,
              minutes: this.endAt.time.mm,
            });

            return isBefore(startDate, endDate);
          },
        },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      endAt: {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
    };
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
    validationErrors: null,
    startAt: {
      required: true,
    },
    endAt: {
      required: true,
    },
    sqlRunner: {},
  },
  data() {
    return {
      zh,
      formatOptions: [
        { value: null, text: "請選擇" },
        { value: 'excel', text: "Excel" },
        { value: 'html', text: "HTML" },
      ],
    }
  },
  methods: {
    editorInit () {
      require('brace/ext/language_tools') //language extension prerequsite...
      require('brace/mode/sql')
      require('brace/theme/chrome')
    },
    async validate() {
      return await this.v$.$validate();
    },
  },
}
</script>
