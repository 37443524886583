<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'SqlRunnerList' }">
              SQL 排程列表
            </b-breadcrumb-item>
            <b-breadcrumb-item active>新增 SQL 排程</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                新增 SQL 排程
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <sql-runner-form
                  ref="form"
                  :start-at="startAt"
                  :end-at="endAt"
                  :sql-runner="sqlRunner"
                  :validation-errors="validationErrors"
                ></sql-runner-form>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
                >返回
              </b-button>
              <b-button
                @click="handleSubmit"
                variant="success"
                >儲存</b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import sqlRunnerApi from "@/apis/sql-runner";
import { format, set } from "date-fns";
import SqlRunnerForm from "./SqlRunnerForm";

export default {
  components: {
    SqlRunnerForm,
  },
  data() {
    return {
      showLoading: false,
      validationErrors: null,
      sqlRunner: {
        description: null,
        cron_schedule: null,
        sql: '',
        format: null,
        start_at: null,
        end_at: null,
        mail_subject: null,
        mail_content: null,
        mail_to: null,
        mail_cc: null,
        mail_bcc: null,
        is_enabled: false,
        send_when_empty: false,
      },
      startAt: {
        date: new Date(),
        time: {
          HH: "00",
          mm: "00",
        },
      },
      endAt: {
        date: new Date(),
        time: {
          HH: "23",
          mm: "59",
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.showLoading = true;

      try {
        await this.doHalderSubmit();
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: "新增失敗",
            type: "error",
          });
        }
      }

      this.showLoading = false;
    },
    async doHalderSubmit() {
      const result = await this.$refs.form.validate();
      if (!result) return;

      this.sqlRunner.start_at = format(
        set(new Date(this.startAt.date), {
          hours: Number(this.startAt.time.HH),
          minutes: Number(this.startAt.time.mm),
          seconds: 0,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
      this.sqlRunner.end_at = format(
        set(new Date(this.endAt.date), {
          hours: this.endAt.time.HH,
          minutes: this.endAt.time.mm,
          seconds: 59,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );

      await sqlRunnerApi.storeSqlRunner(this.sqlRunner);

      this.$swal.fire({
        title: "新增成功",
        type: "success",
      });

      this.$router.push({ name: "SqlRunnerList" });
    },
    handleCancel() {
      this.$router.push({ name: "SqlRunnerList" });
    },
  },
};
</script>
